import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import Loader from '../../Global/HelperComponents/Loader';
import PrivateRoute from './PrivateRoute';

const RouteWrapper = ({ element, isPrivate = false, forAdminOnly = false }) => {
  if (isPrivate) {
    return (
      <Suspense fallback={<Loader />}>
        <PrivateRoute forAdminOnly={forAdminOnly}>{element}</PrivateRoute>
      </Suspense>
    );
  }

  return <Suspense fallback={<Loader />}>{element}</Suspense>;
};

RouteWrapper.propTypes = {
  element: PropTypes.node.isRequired,
  isPrivate: PropTypes.bool,
  forAdminOnly: PropTypes.bool
};

export default RouteWrapper;
