import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../Global/HelperComponents/Modal';
import { Box } from '@mui/material';
import { useTimer } from './timerModalHelpers';
import { useTranslation } from 'react-i18next';

const TimerModal = ({ open, handleClose, handleClick }) => {
  const [countDown] = useTimer(handleClick);
  const { t } = useTranslation();
  return (
    <Modal
      open={open}
      title={t('areYouWorking')}
      handleClose={handleClose}
      buttonTitle={'logout'}
      buttonProps={{ onClick: handleClick }}
    >
      <>
        <Box sx={{ mt: 1, textAlign: 'center' }}>
          {t('logoutIn')} <b>{countDown}</b> {t('seconds')}
        </Box>
      </>
    </Modal>
  );
};

TimerModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleClick: PropTypes.func
};

export default TimerModal;
