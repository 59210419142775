import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Condition from '../../Global/HelperComponents/Condition';
import { deleteNotifications } from '../../api/redux/slices/notificationsSlice';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

export const useNotify = () => {
  const { notifications } = useSelector(state => state.notifications);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const Container = props => <>{props.children}</>;

  const Message = notification => (
    <Container>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {typeof notification.message === 'object' ? (
          <Typography color='black'>
            {t(notification.message.text, notification.message.values)}
          </Typography>
        ) : (
          <Typography color='black'>{t(notification.message)}</Typography>
        )}
        <Condition condition={notification.type === 'error'}>
          <Button
            onClick={() => alert(notification.fullMessage)}
            sx={{ color: 'gray', ml: 'auto' }}
          >
            info
          </Button>
        </Condition>
      </Box>
    </Container>
  );

  const handleNotification = () => {
    notifications.forEach(notification => {
      toast[notification.type](Message(notification), {
        position: notification?.position ?? 'bottom-right'
      });
      dispatch(deleteNotifications());
    });
  };

  useEffect(() => {
    handleNotification();
  }, [notifications]);
};
