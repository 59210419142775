import { createSlice } from '@reduxjs/toolkit';

export const objectTypeSlice = createSlice({
  name: 'objectTypeSlice',
  initialState: {
    objectId: null,
    objectType: null,
    tableRow: null
  },
  reducers: {
    setObjectTypeAndId: (state, action) => {
      state.objectId = action.payload.objectId;
      state.objectType = action.payload.objectType;
      state.tableRow = action.payload.tableRow;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setObjectTypeAndId } = objectTypeSlice.actions;
export default objectTypeSlice.reducer;
