/* eslint-disable */
export const flags = {
  en: (
    <svg width='20' height='20' viewBox='0 0 512 336' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none'>
        <path
          //eslint-disable-next-line
          d='M503.172 335.725H8.828A8.829 8.829 0 0 1 0 326.897V9.104A8.829 8.829 0 0 1 8.828.276h494.345a8.829 8.829 0 0 1 8.828 8.828v317.793a8.83 8.83 0 0 1-8.829 8.828z'
          fill='#41479B'
        />
        <path
          //eslint-disable-next-line
          d='M512 9.104a8.829 8.829 0 0 0-8.828-8.828h-39.495l-163.54 107.147V.276h-88.276v107.147L48.322.276H8.828A8.829 8.829 0 0 0 0 9.104v22.831l140.309 91.927H0v88.276h140.309L0 304.066v22.831a8.829 8.829 0 0 0 8.828 8.828h39.495l163.54-107.147v107.147h88.276V228.578l163.54 107.147h39.495a8.829 8.829 0 0 0 8.828-8.828v-22.831l-140.309-91.927H512v-88.276H371.691L512 31.935V9.104z'
          fill='#F5F5F5'
        />
        <g fill='#FF4B55'>
          <path d='M512 141.518H282.483V.276h-52.966v141.242H0v52.965h229.517v141.242h52.966V194.483H512z' />
          <path
            //eslint-disable-next-line
            d='M178.948 212.138L.25 328.135c.625 4.263 4.14 7.59 8.577 7.59h12.159l190.39-123.587-32.428.001v-.001zm167.44 0H313.96l190.113 123.404c4.431-.472 7.928-4.09 7.928-8.646v-7.258l-165.613-107.5zM0 18.849l161.779 105.014h32.428L5.143 1.137C2.123 2.54 0 5.555 0 9.104v9.745zm332.566 105.014L511.693 7.586c-.744-4.122-4.184-7.309-8.521-7.309h-12.647L300.138 123.863h32.428z'
          />
        </g>
      </g>
    </svg>
  ),
  sk: (
    <svg width='20' height='20' viewBox='0 0 512 336' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none'>
        <path
          d='M512.001 112.092H0V9.103A8.829 8.829 0 0 1 8.828.275h494.345a8.829 8.829 0 0 1 8.828 8.828v102.989z'
          fill='#F5F5F5'
        />
        <path
          d='M503.172 335.724H8.828A8.829 8.829 0 0 1 0 326.896V223.908h512v102.988a8.828 8.828 0 0 1-8.828 8.828z'
          fill='#FF4B55'
        />
        <path fill='#41479B' d='M0 112.088h512V223.9H0z' />
        <path
          //eslint-disable-next-line
          d='M130.225 251.285c-2.613-1.501-64.018-37.216-64.018-92.113V84.138h132.414v75.034c0 54.897-61.405 90.612-64.018 92.113l-2.189 1.249-2.189-1.249z'
          fill='#FF4B55'
        />
        <path
          fill='#F5F5F5'
          // eslint-disable-next-line
          d='M167.094 145.931l-20.809 2.741h-6.935v-12.418h4.161l16.646 2.741-2.254-9.677 2.254-9.677-16.646 2.74h-4.161v-4.194l2.74-20.808-9.676 2.819-9.676-2.819 2.74 20.808v4.194h-4.163l-16.645-2.74 2.254 9.677-2.254 9.677 16.645-2.741h4.163v12.418h-6.936l-20.808-2.741 2.818 9.676-2.818 9.677 20.808-2.741h6.936v31.94h13.872v-31.94h6.935l20.809 2.741-2.818-9.677z'
        />
        <path
          // eslint-disable-next-line
          d='M132.414 181.241c-9.118 0-16.938 5.532-20.302 13.419-.594-.049-1.162-.178-1.767-.178-11.751 0-21.266 9.21-21.94 20.791 17.875 22.004 40.264 35.116 41.82 36.011l2.189 1.25 2.189-1.25c1.556-.895 23.945-14.006 41.82-36.011-.674-11.581-10.189-20.791-21.94-20.791-.605 0-1.173.129-1.767.178-3.364-7.887-11.184-13.419-20.302-13.419z'
          fill='#41479B'
        />
        <path
          // eslint-disable-next-line
          d='M194.207 88.552v70.621c0 52.966-61.793 88.276-61.793 88.276s-61.793-35.31-61.793-88.276V88.552h123.586zm8.828-8.828H61.793v79.449c0 57.428 63.537 94.394 66.241 95.94l4.38 2.503 4.38-2.503c2.705-1.546 66.241-38.513 66.241-95.94V79.724z'
          fill='#F5F5F5'
        />
      </g>
    </svg>
  ),
  de: (
    <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 55.2 38.4'>
      <g fillRule='evenodd' clipRule='evenodd'>
        <path d='M3.03 0h49.13c1.67 0 3.03 1.36 3.03 3.03v32.33c0 1.66-1.36 3.02-3.02 3.03H3.02C1.36 38.4 0 37.03 0 35.37V3.03C0 1.36 1.36 0 3.03 0z' />
        <path
          d='M0 12.8h55.2v22.57c0 1.67-1.36 3.03-3.03 3.03H3.03C1.36 38.4 0 37.04 0 35.37V12.8z'
          fill='#d00'
        />
        <path
          d='M0 25.6h55.2v9.77c0 1.66-1.36 3.02-3.02 3.03H3.03A3.04 3.04 0 010 35.37V25.6z'
          fill='#ffce00'
        />
      </g>
    </svg>
  )
};
