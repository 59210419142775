import React from 'react';
import error_logo from '../../Img/500.png';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageFormWrapper from '../PageFormWrapper';

const ErrorAlert = () => {
  const { t } = useTranslation();

  return (
    <PageFormWrapper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Box>
          <img style={{ width: '100%' }} alt='error logo' src={error_logo} />
        </Box>
        <Typography variant='h5'>{t('somethingWentWrong')}</Typography>
      </Box>
    </PageFormWrapper>
  );
};

export default ErrorAlert;
