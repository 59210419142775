import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import RouteWrapper from './RouteWrapper.js';
import { privatePages, publicPages } from './routes.js';
import { useObjectTypes } from '../../Global/globalHooks.js';

const HomeWrapper = React.lazy(() => import('../../Components/Home/Home'));

const RoutesWrapper = () => {
  //temporary placement of useObjectTypes
  useObjectTypes();

  return (
    <Routes>
      {/* public pages */}
      {publicPages.map(page => (
        <Route
          path={page.path}
          element={<RouteWrapper element={page.element} />}
          key={'page_' + page.path}
        />
      ))}

      {/* private pages */}
      <Route exact path='/' element={<RouteWrapper isPrivate element={<HomeWrapper />} />}>
        <Route
          path={'/'}
          index
          element={<RouteWrapper isPrivate element={privatePages[0].element} />}
        />
        {privatePages
          .filter(page => {
            if (page.webOnly && isMobile) {
              return false;
            }
            return true;
          })
          .map(page => {
            return (
              <Route
                path={page.path}
                element={
                  <RouteWrapper forAdminOnly={page.forAdminOnly} isPrivate element={page.element} />
                }
                key={'page_' + page.path}
              />
            );
          })}
      </Route>
    </Routes>
  );
};

export default RoutesWrapper;
