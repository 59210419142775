import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

export const useLanguage = () => {
  const [language, setLanguage] = useState(false);
  const { i18n } = useTranslation();

  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);

  const toggleLanguage = lang => {
    sessionStorage.setItem('language', lang);
    setLanguage(lang);
    i18n.changeLanguage(lang);
  };

  return { language, toggleLanguage };
};
