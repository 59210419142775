import { createSlice } from '@reduxjs/toolkit';
import { parseCustomProperties } from '../../../Components/Home/Dashboard/DataTable/dataTableHelpers';
import { isEmpty } from 'lodash';

export const dataTableSlice = createSlice({
  name: 'dataTableSlice',
  initialState: {
    loading: false,
    rows: [],
    filteredRows: [],
    isFilterActive: false,
    columns: [],
    dataTableViewType: 'DOCUMENT', // DOCUMENT,TABLE,HTML,
    defaultDataTableViewType: 'DOCUMENT',
    selectedRow: {},
    selectedRows: []
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setDataTableViewType: (state, action) => {
      state.dataTableViewType = action.payload;
      if (!state.isFilterActive) state.defaultDataTableViewType = action.payload;
    },
    setRows: (state, action) => {
      state.rows = action.payload;
    },
    setFilteredRows: (state, action) => {
      state.filteredRows = action.payload;
    },
    setIsFilterActive: (state, action) => {
      state.isFilterActive = action.payload;
      if (!action.payload) {
        state.dataTableViewType = state.defaultDataTableViewType;
      }
    },
    setColumns: (state, action) => {
      state.columns = action.payload;
    },
    setSelectedRow: (state, action) => {
      state.selectedRow = action.payload;
      if (state.isFilterActive) {
        state.dataTableViewType = action.payload?.fileType;
      }
    },
    setSelectedRows: (state, action) => {
      state.selectedRows = action.payload;
      if (isEmpty(action.payload)) {
        state.selectedRow = {};
      }
    },
    deleteRow: (state, action) => {
      let rowsCopy = state.isFilterActive ? [...state.filteredRows] : [...state.rows];
      let row = rowsCopy.find(row => row.id === action.payload);
      if (row) {
        if (state.isFilterActive) {
          state.filteredRows = state.filteredRows.filter(row => row.id !== action.payload);
        } else {
          state.rows = state.rows.filter(row => row.id !== action.payload);
        }
        state.selectedRow = {};
      }
    },
    deleteRows: (state, action) => {
      if (state.isFilterActive) {
        state.filteredRows = state.filteredRows.filter(row => !action.payload.includes(row.id));
      } else {
        state.rows = state.rows.filter(row => !action.payload.includes(row.id));
      }
      state.selectedRow = {};
    },
    updateRow: (state, action) => {
      let rowsCopy = state.isFilterActive ? [...state.filteredRows] : [...state.rows];
      let rowIndex = rowsCopy.findIndex(row => row.id === action.payload.id);

      if (rowIndex !== -1) {
        rowsCopy[rowIndex] = action.payload;
        state.rows = rowsCopy;
        state.selectedRow = action.payload;
      }
    },
    updateRowCustomProperties: (state, action) => {
      let rowsCopy = state.isFilterActive ? [...state.filteredRows] : [...state.rows];
      let rowIndex = rowsCopy.findIndex(row => row.id === state.selectedRow.id);
      if (rowIndex !== -1) {
        const newRow = { ...state.selectedRow, customProperties: action.payload };
        state.selectedRow = newRow;
        const customProperties = parseCustomProperties(newRow);
        rowsCopy[rowIndex] = { ...state.selectedRow, ...customProperties };
        state.rows = rowsCopy;
      }
    },
    addRow: (state, action) => {
      state.rows.push(action.payload);
    },
    addRows: (state, action) => {
      let rowsCopy = [...state.rows];
      state.rows = [...rowsCopy, ...action.payload];
    },
    renameTitle: (state, action) => {
      let rowsCopy = state.isFilterActive ? [...state.filteredRows] : [...state.rows];
      let rowIndex = rowsCopy.findIndex(row => row.id === state.selectedRow.id);

      if (rowIndex !== -1) {
        rowsCopy[rowIndex].title = action.payload;
        state.rows = rowsCopy;
        state.selectedRow.title = action.payload;
      }
    },
    resetState: state => {
      state.rows = [];
      state.columns = [];
      state.selectedRow = {};
      state.filteredRows = [];
      state.isFilterActive = false;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setDataTableViewType,
  setRows,
  setColumns,
  setSelectedRow,
  setSelectedRows,
  setLoading,
  deleteRow,
  deleteRows,
  updateRow,
  updateRowCustomProperties,
  addRow,
  addRows,
  renameTitle,
  resetState,
  setFilteredRows,
  setIsFilterActive
} = dataTableSlice.actions;
export default dataTableSlice.reducer;
