export const light = themeColors => {
  return {
    mode: 'light',
    background: {
      paper: '#F3F3F3',
      default: '#F3F3F3',
      paperLight: '#F3F3F3'
    },
    buttons: {
      main: themeColors.PRIMARY
    },
    primary: {
      main: themeColors.PRIMARY,
      main2: themeColors.PRIMARY,
      light: themeColors.LIGHTPRIMARY,
      dark: themeColors.DARKPRIMARY
    },
    secondary: {
      main: themeColors.PRIMARY
    },
    subtitle: themeColors.GRAY,
    hover: themeColors.HOVER,
    themeHover: themeColors.LIGHTPRIMARY,
    avatarColor: {
      light: themeColors.WHITE,
      dark: themeColors.GRAY
    },
    shadow: {
      primary: '0px 2px 15px rgb(0 0 0 / 0.3)',
      secondary: '0px 2px 5px rgb(0 0 0 / 0.3)'
    },
    border: '1px solid rgba(209, 209, 209, 0.5)',
    themeBorder: `1px solid ${themeColors.PRIMARY}`,
    borderColor: 'rgba(209, 209, 209, 0.5)'
  };
};

export const dark = themeColors => {
  return {
    mode: 'dark',
    background: {
      paper: themeColors.DARKPAPER,
      default: themeColors.DARKDARKPAPER,
      paperLight: themeColors.DARKLIGHTPAPER
    },
    buttons: {
      main: themeColors.YELLOW
    },
    primary: {
      main: themeColors.PRIMARY,
      main2: themeColors.WHITE,
      light: themeColors.LIGHTPRIMARY,
      dark: themeColors.DARKPRIMARY
    },
    secondary: {
      main: themeColors.SECONDARY
    },
    subtitle: themeColors.GRAY,
    hover: themeColors.HOVER,
    themeHover: themeColors.LIGHTPRIMARY,
    avatarColor: {
      light: themeColors.WHITE,
      dark: themeColors.GRAY
    },
    shadow: {
      primary: '0px 2px 10px rgb(0 0 0 / 0.3)',
      secondary: '0px 2px 5px rgb(0 0 0 / 0.3)'
    },
    border: '1px solid rgba(160, 160, 160, 0.5)',
    borderColor: 'rgba(160, 160, 160, 0.5)',
    themeBorder: `1px solid ${themeColors.PRIMARY}`
  };
};
